
























































































































































































































































































































































































































































































































@import "../../assets/scss/_multiselect.scss";
.pagination{
  display: inline-block;
  padding: 0 65px;
  position: relative;
  .next,
  .prev{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .next{
    right: 0;
  }
  .prev{
    left: 0;
  }
  ul{
    display: flex;
    align-items: center;
  }
  li{
    margin-right: 5px;
    &:last-child{
      margin-right: 0;
    }
  }
}
.thumbnail-professions-head{
  display: flex;
  align-items: center;
  padding: 0 16px;
  margin-bottom: 8px;
}
.thumbnail-professions{
  background-color: #fff;
  border-radius: 8px;
  padding: 14px 16px;
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  &:last-child{
    margin-bottom: 0;
  }
  .check{
    line-height: 0;
  }
  .info-more{
    margin-left: auto;
  }
  .check,
  .name,
  .type,
  .salary{
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
.thumbnail-professions-head,
.thumbnail-professions{
  .check{
    width: 100%;
    max-width: 22px;
    margin-right: 10px;
    line-height: 0;
  }
  .name{
    width: 100%;
    max-width: 25%;
    margin-right: 10px;
  }
  .type{
    width: 100%;
    max-width: 20%;
    margin-right: 10px;
  }
  .skills{
    width: 100%;
    max-width: 32%;
    margin-right: 10px;
  }
  .salary{
    width: 100%;
    max-width: 15%;
    margin-right: 10px;
  }
}
